<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <span class="muted caption">Inquiry Source</span>
      <v-select
        :items="items"
        label="Select Inquiry Source"
        v-model="model"
        class="pt-0 currencyTitle"
        single-line
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["onselect"],
  data() {
    return {
      valid: true,
      lazy: false,

      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        required: (value) => !!value || "This field is required.",
      },
      items: ["Email", "Call"],
      model: null,
    };
  },
  watch: {
    model(val) {
      if (this.onselect) {
        this.onselect(this.model);
      }
    },
  },
};
</script>

<style>
.currencyTitle {
  margin-top: -2px !important;
}
</style>
