<template>
  <div>
    <span class="muted caption" >Shipment Type</span>
    <v-select :items="items" label="Select Shipment Type" v-model="model"  class="pt-0 currencyTitle" single-line>
    </v-select>
  </div>
</template>

<script>
export default {
  props: ["onselect"],
  data() {
    return {
      items: ["Local", "Cross Country", "Cross Continent"],
      model: null
    };
  },
  watch: {
    model(val) {
      if (this.onselect) {
        this.onselect(this.model);
      }
    }
  }
};
</script>

<style>
.currencyTitle {
  margin-top: -2px !important;
}
</style>
